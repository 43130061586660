/* LIGHT THEME COLORS */
:root, .light, .light-theme {
    --blue-1: #fcfdff;
    --blue-2: #f6f9ff;
    --blue-3: #edf2ff;
    --blue-4: #dee9ff;
    --blue-5: #cfdeff;
    --blue-6: #bdd1ff;
    --blue-7: #a5bdff;
    --blue-8: #85a1ff;
    --blue-9: #162164;
    --blue-10: #27377c;
    --blue-11: #475eb6;
    --blue-12: #1c296c;

    --blue-a1: #0055ff03;
    --blue-a2: #0055ff09;
    --blue-a3: #0047ff12;
    --blue-a4: #0055ff21;
    --blue-a5: #0050ff30;
    --blue-a6: #004eff42;
    --blue-a7: #0145ff5a;
    --blue-a8: #013bff7a;
    --blue-a9: #000c55e9;
    --blue-a10: #001364d8;
    --blue-a11: #00209ab8;
    --blue-a12: #000f5ae3;

    --blue-contrast: #fff;
    --blue-surface: #f4f8ffcc;
    --blue-indicator: #162164;
    --blue-track: #162164;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        :root, .light, .light-theme {
            --blue-1: oklch(99.4% 0.0031 269.9);
            --blue-2: oklch(98.1% 0.0111 269.9);
            --blue-3: oklch(96.1% 0.0209 269.9);
            --blue-4: oklch(93.5% 0.0421 269.9);
            --blue-5: oklch(90.4% 0.0628 269.9);
            --blue-6: oklch(86.5% 0.0831 269.9);
            --blue-7: oklch(80.8% 0.1057 269.9);
            --blue-8: oklch(72.9% 0.1412 269.9);
            --blue-9: oklch(28.8% 0.118 269.9);
            --blue-10: oklch(36.5% 0.118 269.9);
            --blue-11: oklch(51% 0.1412 269.9);
            --blue-12: oklch(31.5% 0.118 269.9);

            --blue-a1: color(display-p3 0.0235 0.349 1 / 0.012);
            --blue-a2: color(display-p3 0.0196 0.2667 0.8784 / 0.032);
            --blue-a3: color(display-p3 0.0078 0.2392 0.9451 / 0.067);
            --blue-a4: color(display-p3 0.0039 0.2941 0.9373 / 0.122);
            --blue-a5: color(display-p3 0.0039 0.2706 0.9373 / 0.177);
            --blue-a6: color(display-p3 0.0039 0.2471 0.9373 / 0.244);
            --blue-a7: color(display-p3 0.0039 0.2157 0.9451 / 0.334);
            --blue-a8: color(display-p3 0.0039 0.1843 0.9412 / 0.455);
            --blue-a9: color(display-p3 0 0.0392 0.3137 / 0.906);
            --blue-a10: color(display-p3 0 0.0627 0.3647 / 0.836);
            --blue-a11: color(display-p3 0 0.0941 0.5608 / 0.702);
            --blue-a12: color(display-p3 0 0.0431 0.3255 / 0.879);

            --blue-contrast: #fff;
            --blue-surface: color(display-p3 0.9569 0.9725 1 / 0.8);
            --blue-indicator: oklch(28.8% 0.118 269.9);
            --blue-track: oklch(28.8% 0.118 269.9);
        }
    }
}

:root, .light, .light-theme {
    --gray-1: #fcfcfd;
    --gray-2: #f9f9fb;
    --gray-3: #eff0f3;
    --gray-4: #e7e8ec;
    --gray-5: #e0e1e6;
    --gray-6: #d8d9e0;
    --gray-7: #cdced7;
    --gray-8: #b9bbc6;
    --gray-9: #8b8d98;
    --gray-10: #80828d;
    --gray-11: #62636c;
    --gray-12: #1e1f24;

    --gray-a1: #00005503;
    --gray-a2: #00005506;
    --gray-a3: #00104010;
    --gray-a4: #000b3618;
    --gray-a5: #0009321f;
    --gray-a6: #00073527;
    --gray-a7: #00063332;
    --gray-a8: #00083046;
    --gray-a9: #00051d74;
    --gray-a10: #00051b7f;
    --gray-a11: #0002119d;
    --gray-a12: #000107e1;

    --gray-contrast: #FFFFFF;
    --gray-surface: #ffffffcc;
    --gray-indicator: #8b8d98;
    --gray-track: #8b8d98;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        :root, .light, .light-theme {
            --gray-1: oklch(99.1% 0.0015 277.7);
            --gray-2: oklch(98.2% 0.003 277.7);
            --gray-3: oklch(95.6% 0.0045 277.7);
            --gray-4: oklch(93.1% 0.0061 277.7);
            --gray-5: oklch(91% 0.0077 277.7);
            --gray-6: oklch(88.8% 0.0093 277.7);
            --gray-7: oklch(85.3% 0.0117 277.7);
            --gray-8: oklch(79.4% 0.016 277.7);
            --gray-9: oklch(64.6% 0.0165 277.7);
            --gray-10: oklch(61% 0.0161 277.7);
            --gray-11: oklch(50.3% 0.0139 277.7);
            --gray-12: oklch(24.1% 0.0099 277.7);

            --gray-a1: color(display-p3 0.0235 0.0235 0.349 / 0.012);
            --gray-a2: color(display-p3 0.0235 0.0235 0.349 / 0.024);
            --gray-a3: color(display-p3 0.0078 0.0667 0.2549 / 0.063);
            --gray-a4: color(display-p3 0.0118 0.051 0.2157 / 0.095);
            --gray-a5: color(display-p3 0.0039 0.0392 0.2 / 0.122);
            --gray-a6: color(display-p3 0.0039 0.0275 0.1804 / 0.153);
            --gray-a7: color(display-p3 0.0078 0.0275 0.1843 / 0.197);
            --gray-a8: color(display-p3 0.0039 0.0314 0.1765 / 0.275);
            --gray-a9: color(display-p3 0.0039 0.0196 0.1059 / 0.455);
            --gray-a10: color(display-p3 0.0039 0.0196 0.098 / 0.499);
            --gray-a11: color(display-p3 0 0.0078 0.0588 / 0.616);
            --gray-a12: color(display-p3 0 0.0039 0.0275 / 0.883);

            --gray-contrast: #FFFFFF;
            --gray-surface: color(display-p3 1 1 1 / 80%);
            --gray-indicator: oklch(64.6% 0.0165 277.7);
            --gray-track: oklch(64.6% 0.0165 277.7);
        }
    }
}

:root, .light, .light-theme, .radix-themes {
    --color-background: #fff;
}
