
.Header {
    background: var(--color-background);
}


.Footer {
  background: var(--color-background);
}

.PageBlock {
    background: var(--color-background);
}

.MainWrapper {
  background: var(--color-background-deep);
    min-height: 100dvh;
}

.rt-Section + .rt-Section {
    padding-top: 0;
}

button:not(:disabled) {
    cursor: pointer;
}