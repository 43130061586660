@import "./colors.light.css";
@import "./colors.dark.css";


.light .radix-themes {
  /*--color-background: var(--gray-1);*/
  --color-background-deep: var(--gray-3);
}

.dark .radix-themes {
  --color: red;
  --color-background: var(--gray-2);
  --color-background-deep: var(--gray-1);
}