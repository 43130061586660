/* DARK THEME COLORS  */
.dark, .dark-theme {
    --blue-1: #0c111c;
    --blue-2: #111725;
    --blue-3: #172448;
    --blue-4: #1d2e61;
    --blue-5: #243974;
    --blue-6: #2d4484;
    --blue-7: #375098;
    --blue-8: #405eb2;
    --blue-9: #3d63dd;
    --blue-10: #3f5cb0;
    --blue-11: #93b4ff;
    --blue-12: #d5e2ff;

    --blue-a1: #0012fb0c;
    --blue-a2: #1156f916;
    --blue-a3: #2b64ff3b;
    --blue-a4: #3567ff56;
    --blue-a5: #3f71fd6b;
    --blue-a6: #4b7afd7c;
    --blue-a7: #5480ff91;
    --blue-a8: #5783ffad;
    --blue-a9: #4571ffdb;
    --blue-a10: #5580feab;
    --blue-a11: #93b4ff;
    --blue-a12: #d5e2ff;

    --blue-contrast: #fff;
    --blue-surface: #111d3980;
    --blue-indicator: #3d63dd;
    --blue-track: #3d63dd;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        .dark, .dark-theme {
            --blue-1: oklch(17.8% 0.0247 266.8);
            --blue-2: oklch(20.7% 0.0302 266.8);
            --blue-3: oklch(27.1% 0.0694 266.8);
            --blue-4: oklch(31.8% 0.0933 266.8);
            --blue-5: oklch(36.1% 0.1046 266.8);
            --blue-6: oklch(40.4% 0.1106 266.8);
            --blue-7: oklch(45% 0.1203 266.8);
            --blue-8: oklch(50.3% 0.1373 266.8);
            --blue-9: oklch(54.3% 0.1913 266.8);
            --blue-10: oklch(49.7% 0.1373 266.8);
            --blue-11: oklch(77.7% 0.1234 266.8);
            --blue-12: oklch(91.1% 0.0428 266.8);

            --blue-a1: color(display-p3 0 0.0706 0.9804 / 0.043);
            --blue-a2: color(display-p3 0.1176 0.3608 1 / 0.08);
            --blue-a3: color(display-p3 0.2275 0.4039 1 / 0.223);
            --blue-a4: color(display-p3 0.2627 0.4196 1 / 0.324);
            --blue-a5: color(display-p3 0.302 0.451 1 / 0.4);
            --blue-a6: color(display-p3 0.3451 0.4902 1 / 0.467);
            --blue-a7: color(display-p3 0.3725 0.5098 1 / 0.547);
            --blue-a8: color(display-p3 0.3843 0.5255 1 / 0.652);
            --blue-a9: color(display-p3 0.3176 0.451 1 / 0.824);
            --blue-a10: color(display-p3 0.3843 0.5176 1 / 0.643);
            --blue-a11: color(display-p3 0.6196 0.7216 1 / 0.975);
            --blue-a12: color(display-p3 0.8549 0.898 1 / 0.988);

            --blue-contrast: #fff;
            --blue-surface: color(display-p3 0.0706 0.1098 0.2118 / 0.5);
            --blue-indicator: oklch(54.3% 0.1913 266.8);
            --blue-track: oklch(54.3% 0.1913 266.8);
        }
    }
}


.dark, .dark-theme {
    --gray-1: #111113;
    --gray-2: #19191b;
    --gray-3: #222325;
    --gray-4: #292a2e;
    --gray-5: #303136;
    --gray-6: #393a40;
    --gray-7: #46484f;
    --gray-8: #5f606a;
    --gray-9: #6c6e79;
    --gray-10: #797b86;
    --gray-11: #b2b3bd;
    --gray-12: #eeeef0;

    --gray-a1: #1111bb03;
    --gray-a2: #cbcbf90b;
    --gray-a3: #d6e2f916;
    --gray-a4: #d1d9f920;
    --gray-a5: #d7ddfd28;
    --gray-a6: #d9defc33;
    --gray-a7: #dae2fd43;
    --gray-a8: #e0e3fd60;
    --gray-a9: #e0e4fd70;
    --gray-a10: #e3e7fd7e;
    --gray-a11: #eff0feb9;
    --gray-a12: #fdfdffef;

    --gray-contrast: #FFFFFF;
    --gray-surface: rgba(0, 0, 0, 0.05);
    --gray-indicator: #6c6e79;
    --gray-track: #6c6e79;
}

@supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
        .dark, .dark-theme {
            --gray-1: oklch(17.8% 0.0042 277.7);
            --gray-2: oklch(21.5% 0.004 277.7);
            --gray-3: oklch(25.5% 0.0055 277.7);
            --gray-4: oklch(28.4% 0.0075 277.7);
            --gray-5: oklch(31.4% 0.0089 277.7);
            --gray-6: oklch(35% 0.01 277.7);
            --gray-7: oklch(40.2% 0.0121 277.7);
            --gray-8: oklch(49.2% 0.0157 277.7);
            --gray-9: oklch(54% 0.0167 277.7);
            --gray-10: oklch(58.6% 0.0165 277.7);
            --gray-11: oklch(77% 0.0138 277.7);
            --gray-12: oklch(94.9% 0.0026 277.7);

            --gray-a1: color(display-p3 0.0667 0.0667 0.9412 / 0.009);
            --gray-a2: color(display-p3 0.8 0.8 0.9804 / 0.043);
            --gray-a3: color(display-p3 0.851 0.898 0.9882 / 0.085);
            --gray-a4: color(display-p3 0.8392 0.8706 1 / 0.122);
            --gray-a5: color(display-p3 0.8471 0.8745 1 / 0.156);
            --gray-a6: color(display-p3 0.8784 0.898 1 / 0.194);
            --gray-a7: color(display-p3 0.8745 0.9059 0.9961 / 0.257);
            --gray-a8: color(display-p3 0.8941 0.9059 1 / 0.37);
            --gray-a9: color(display-p3 0.8902 0.9098 1 / 0.433);
            --gray-a10: color(display-p3 0.902 0.9176 1 / 0.488);
            --gray-a11: color(display-p3 0.9451 0.949 1 / 0.719);
            --gray-a12: color(display-p3 0.9922 0.9922 1 / 0.937);

            --gray-contrast: #FFFFFF;
            --gray-surface: color(display-p3 0 0 0 / 5%);
            --gray-indicator: oklch(54% 0.0167 277.7);
            --gray-track: oklch(54% 0.0167 277.7);
        }
    }
}

.dark, .dark-theme, :is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
    --color-background: #111;
}